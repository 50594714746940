import {Button, Link as CLink} from '@chakra-ui/react';
import Link from 'next/link';
import {catalogPath, learnPath} from 'utils/routeFactory';
import SuccessfulEnrollment from '../Messages/SuccessfulEnrollment';
import {closeModal} from '../AppModal';

export const youreEnrolled = (
  courseName: string,
  cohortSlug: string,
  onClick = () => closeModal()
) => ({
  title: "You're Enrolled!",
  Body: <SuccessfulEnrollment name={courseName} />,
  Footer: (
    <>
      <Link href={learnPath().schedule(cohortSlug)} passHref>
        <Button
          mr={5}
          size="lg"
          as={CLink}
          layerStyle="button-link"
          variant="solid"
          onClick={onClick}
        >
          Start Learning
        </Button>
      </Link>
      <Link href={catalogPath().catalog()} passHref>
        <Button size="lg" variant="outline" as={CLink} layerStyle="button-link" onClick={onClick}>
          Discover More Courses
        </Button>
      </Link>
    </>
  )
});
