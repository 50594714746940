import {Text} from '@chakra-ui/react';
import {FC} from 'react';

const SuccessfulEnrollment: FC<React.PropsWithChildren<{name: string}>> = ({name}) => (
  <Text textAlign="center" fontSize="lg">
    Welcome! You&apos;ve successfully enrolled in <Text as="b">{name}</Text>!
  </Text>
);

export default SuccessfulEnrollment;
