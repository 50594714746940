import {Button, ButtonProps, useDisclosure} from '@chakra-ui/react';
import {
  CourseTypeEnum,
  Scalars,
  useGetCohortEnrollmentStatusActionMutation
} from 'generated/graphql';
import {FC, MouseEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useEnroll} from 'utils/enrollments/hooks/useEnroll';
import TeamEnrollModal from 'components/Modals/TeamEnrollModal';
import {useSession} from 'context/SessionContext';
import {useAnalytics} from 'context/AnalyticsWebContext';
import {useEnrollmentContext} from 'context/EnrollmentContext';
import {openModal} from 'components/Modals/AppModal';
import {managerOnlyEnrollment} from 'components/Modals/configs/managerOnlyEnrollmentError';
import {coursePath} from 'utils/routeFactory';
import BuyCreditsModal from 'components/Modals/BuyCreditsModal';

type EnrollButtonProps = ButtonProps & {
  cohortId: Scalars['uuid'];
  courseType?: CourseTypeEnum;
  onCloseTeamEnrollModal?: () => void;
  onEnrollmentChange?: (action: 'enroll' | 'unenroll', userIds: string[]) => void;
  selectedMembers?: Scalars['uuid'][];
  closeOnEnroll?: boolean;
};

const EnrollButton: FC<React.PropsWithChildren<Omit<EnrollButtonProps, 'onClick'>>> = ({
  cohortId,
  courseType,
  onCloseTeamEnrollModal,
  onEnrollmentChange,
  selectedMembers,
  closeOnEnroll,
  ...props
}) => {
  const learnMoreDisclosure = useDisclosure();
  const analyticsWebClient = useAnalytics();
  const {data: session} = useSession();
  const waitlistFeatureEnabled = analyticsWebClient?.checkFeatureFlag('enrollment-waitlist');

  const userAbilities = session?.user.abilities;

  const {actAsSingleEnrollmentByManager, cohortSlug} = useEnrollmentContext();

  const {
    canEnroll,
    enroll,
    enrolling,
    isEnrollable,
    isEnrolled,
    isWaitlisted,
    unenroll,
    unenrolling
  } = useEnroll({id: cohortId, courseType, onEnrollmentChange});

  const [isFull, setIsFull] = useState(false);
  const [fetchCohortEnrollmentStatus, {called: statusCalled}] =
    useGetCohortEnrollmentStatusActionMutation({
      fetchPolicy: 'no-cache'
    });

  useEffect(() => {
    if (!statusCalled && waitlistFeatureEnabled) {
      fetchCohortEnrollmentStatus({variables: {cohortId}}).then((result) =>
        setIsFull(result.data?.GetCohortEnrollmentStatus?.isFull ?? false)
      );
    }
  }, [waitlistFeatureEnabled, statusCalled, fetchCohortEnrollmentStatus, cohortId]);

  const loading = useMemo(() => enrolling || unenrolling, [enrolling, unenrolling]);
  const buttonLabel = useMemo(() => {
    if (waitlistFeatureEnabled) {
      if (courseType === CourseTypeEnum.LIGHTNING_SESSION) {
        if (isEnrolled) {
          return 'Unenroll';
        }

        if (isWaitlisted) {
          return 'Leave Waitlist';
        }

        return isFull ? 'Join Waitlist' : 'Enroll';
      } else {
        return isEnrolled ? 'Unenroll' : 'Enroll';
      }
    }

    return isEnrolled ? 'Unenroll' : 'Enroll';
  }, [courseType, isEnrolled, isFull, isWaitlisted, waitlistFeatureEnabled]);
  const isLearnerUnableToEnroll = useMemo(
    () => !statusCalled || (!canEnroll && !isEnrolled),
    [canEnroll, statusCalled, isEnrolled]
  );
  const isManagerUnableToEnrollDueToLackOfCreditsBought = useMemo(
    () => !statusCalled || !isEnrollable,
    [statusCalled, isEnrollable]
  );

  const handleEnrollmentToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (waitlistFeatureEnabled ? isEnrolled || isWaitlisted : isEnrolled) {
        unenroll();
      } else {
        enroll?.();
      }
    },
    [isEnrolled, isWaitlisted, enroll, unenroll, waitlistFeatureEnabled]
  );

  const handleLearnMore = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      learnMoreDisclosure.onOpen();
    },
    [learnMoreDisclosure]
  );

  const handleShareWithManager = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    openModal(
      managerOnlyEnrollment(
        `You don't have any credits.`,
        coursePath({absolute: true}).sessionDetail(cohortSlug)
      )
    );
  };

  if (userAbilities?.editTeamEnrollments && !actAsSingleEnrollmentByManager) {
    return (
      <>
        {isManagerUnableToEnrollDueToLackOfCreditsBought ? (
          <>
            <Button variant="outline" onClick={handleLearnMore}>
              Learn More
            </Button>
            <BuyCreditsModal disclosure={learnMoreDisclosure} />
          </>
        ) : (
          <TeamEnrollModal
            cohortId={cohortId}
            onClose={onCloseTeamEnrollModal}
            onEnrollmentChange={onEnrollmentChange}
            selectedMembers={selectedMembers}
            size={props.size ?? 'md'}
            closeOnEnroll={closeOnEnroll}
          />
        )}
      </>
    );
  }

  if (session && isLearnerUnableToEnroll) {
    return (
      <Button variant="outline" onClick={handleShareWithManager}>
        Learn More
      </Button>
    );
  }

  return (
    <Button
      {...props}
      size={props.size ?? 'md'}
      width="12ch"
      name={buttonLabel}
      role="button"
      variant={props.variant ?? 'solid'}
      textTransform="capitalize"
      onClick={handleEnrollmentToggle}
      isLoading={loading}
      color={isEnrolled ? 'berry' : undefined}
    >
      {buttonLabel}
    </Button>
  );
};

export default EnrollButton;
