import {Scalars} from 'generated/graphql';
import {createContext, FC, useMemo, useContext} from 'react';

type EnrollmentContextProps = {
  openForEnrollment: boolean;
  cohortId: Scalars['uuid'];
  cohortSlug: string;
  courseName: string;
  cohortStartTime?: string;
  learnerName?: string;
  learnerId?: Scalars['uuid'];
  enrollmentStatus?: 'ENROLLED' | 'WAITLISTED' | 'NOT_ENROLLED';
  actAsSingleEnrollmentByManager?: boolean;
};

const EnrollmentContext = createContext<EnrollmentContextProps>({
  openForEnrollment: false,
  cohortId: '',
  cohortSlug: '',
  courseName: ''
});

export const EnrollmentContextProvider: FC<React.PropsWithChildren<EnrollmentContextProps>> = ({
  children,
  openForEnrollment,
  cohortId,
  cohortSlug,
  cohortStartTime,
  courseName,
  learnerName,
  learnerId,
  enrollmentStatus,
  actAsSingleEnrollmentByManager
}) => {
  const value = useMemo(
    () => ({
      openForEnrollment,
      cohortId,
      cohortSlug,
      cohortStartTime,
      courseName,
      learnerName,
      learnerId,
      enrollmentStatus,
      actAsSingleEnrollmentByManager
    }),
    [
      openForEnrollment,
      cohortId,
      cohortSlug,
      cohortStartTime,
      courseName,
      learnerName,
      learnerId,
      enrollmentStatus,
      actAsSingleEnrollmentByManager
    ]
  );

  return <EnrollmentContext.Provider value={value}>{children}</EnrollmentContext.Provider>;
};

export const useEnrollmentContext = () => {
  return useContext(EnrollmentContext);
};
