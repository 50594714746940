import {useSession} from 'context/SessionContext';
import {useGetUserCreditModelLazyQuery} from 'generated/graphql';
import {useEffect, useMemo} from 'react';

export enum CreditModel {
  LEGACY = 'legacy',
  V1 = 'v1',
  V2 = 'v2'
}

export const useCreditModel = () => {
  const {data: session} = useSession();
  const [fetchUserCreditModel, {data: userCreditModel}] = useGetUserCreditModelLazyQuery({
    variables: {userId: session?.user.id}
  });

  useEffect(() => {
    if (session?.user.selectedOrg.creditModel) return;
    fetchUserCreditModel();
  }, [session?.user.selectedOrg.creditModel, fetchUserCreditModel]);

  const creditModel = useMemo(
    () =>
      session?.user.selectedOrg.creditModel ??
      userCreditModel?.user?.organizationUsers?.[0]?.organization?.meta?.creditModel,
    [session?.user.selectedOrg.creditModel, userCreditModel?.user?.organizationUsers]
  ) as CreditModel | undefined;

  return creditModel;
};
