import {Button, ButtonProps, Link} from '@chakra-ui/react';
import {FC} from 'react';

const ContactUs: FC<React.PropsWithChildren<ButtonProps>> = (props) => (
  <Button
    {...props}
    size="lg"
    variant="solid"
    as={Link}
    href="https://www.salesimpact.io/contact-us"
    target="_blank"
  >
    Contact Us
  </Button>
);

export default ContactUs;
