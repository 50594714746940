import {ChevronDownIcon} from '@chakra-ui/icons';
import {Box, Heading, Text} from '@chakra-ui/react';
import {ContactUsButton} from 'components/Buttons';

const ContactHero = () => (
  <Box
    minW="100%"
    bgColor="gray.900"
    position="relative"
    paddingTop="8rem"
    paddingLeft="4rem"
    paddingBottom="5.5rem"
    overflow="hidden"
    boxShadow="base"
    zIndex={0}
    sx={{
      '&:after': {
        content: '" "',
        position: 'absolute',
        transform: 'translate(48%, -25%)',
        right: 0,
        top: 0,
        height: '250%',
        aspectRatio: '1/1',
        borderStyle: 'solid',
        borderWidth: '2.5rem',
        borderColor: 'var(--chakra-colors-gray-800)',
        borderRadius: '50%',
        zIndex: -1
      }
    }}
  >
    <Box maxW="36rem">
      <Heading size="lg" mb="1rem">
        Learn from the World&apos;s <br aria-hidden /> Leading{' '}
        <Text as="abbr" title="Go to market">
          GTM
        </Text>{' '}
        Experts.
      </Heading>
      <Text textStyle="text-lg">
        Don&apos;t miss out! Start leveling up with unlimited access to the world&apos;s best{' '}
        <Text as="abbr" title="Go to market">
          GTM
        </Text>{' '}
        coaches and courses today!
      </Text>
      <ContactUsButton mt="1rem" />
    </Box>
    <Box
      position="absolute"
      aria-hidden
      top="0"
      right="8%"
      overflow="hidden"
      width="6rem"
      height="100%"
      maxHeight="25rem"
      transform="translateY(-10%)"
      zIndex={-1}
      sx={{
        '> svg': {
          position: 'absolute',
          right: '50%',
          transform: 'translateX(50%)',
          width: '20rem',
          height: '20rem',
          scale: '120%',

          '&:last-of-type': {
            top: '20%'
          }
        }
      }}
    >
      <ChevronDownIcon
        aria-hidden
        opacity="0.5"
        color="var(--chakra-colors-teal-400)"
        fontSize="20rem"
      />
      <ChevronDownIcon
        aria-hidden
        opacity="0.5"
        color="var(--chakra-colors-teal-400)"
        fontSize="20rem"
      />
    </Box>
  </Box>
);

export default ContactHero;
