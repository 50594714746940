import {Avatar, Box, HStack, Text} from '@chakra-ui/react';
import React, {useMemo} from 'react';

interface TeamMemberAvatarProps {
  avatarGap?: number;
  email: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

const TeamMemberAvatar: React.FC<TeamMemberAvatarProps> = ({
  avatarGap = 1,
  email,
  firstName,
  lastName,
  fullName
}) => {
  const name = useMemo(() => {
    if (fullName) return fullName;
    return firstName + ' ' + lastName;
  }, [fullName, firstName, lastName]);

  return (
    <HStack gap={avatarGap}>
      <Box>
        <Avatar name={name} size="md" role="presentation" style={{width: 32, height: 32}} />
      </Box>
      <Box minWidth={0}>
        <Text overflowX="hidden" textOverflow="ellipsis">
          {name}
        </Text>
        <Text
          overflowX="hidden"
          textOverflow="ellipsis"
          fontSize="xs"
          color="gray.300"
          style={{margin: 0}}
        >
          {email}
        </Text>
      </Box>
    </HStack>
  );
};

export default TeamMemberAvatar;
