import {ModalsBehaviorType, closeModal} from '../AppModal';
import TextMessage from '../Messages/Text';
import {VStack, Text, Link, HStack, IconButton, Tooltip, useToast, Input} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-regular-svg-icons';
import {useCallback, useRef} from 'react';

export const managerOnlyEnrollment = (label: string, copyLink?: string): ModalsBehaviorType => ({
  variant: 'error',
  title: 'Unable to Enroll in course',
  Body: <Body label={label} copyLink={copyLink} />,
  modalProps: {
    motionPreset: 'slideInBottom'
  }
});

const Body = (props: {label: string; copyLink?: string}) => {
  const toast = useToast();
  const inputEl = useRef<HTMLInputElement>(null);

  const copyLink = useCallback(() => {
    if (props.copyLink) {
      inputEl.current?.select();
      navigator.clipboard.writeText(props.copyLink);
      toast({
        title: 'Link copied to clipboard',
        status: 'success',
        duration: 2000,
        isClosable: true
      });
    }
  }, [props.copyLink, toast]);

  return (
    <VStack>
      <TextMessage>{props.label}</TextMessage>
      {props.copyLink && (
        <>
          <TextMessage>Share the course link with your manager to get enrolled.</TextMessage>
          <HStack width="100%">
            <Input
              ref={inputEl}
              width="100%"
              flex={1}
              background="gray.900"
              border="3x solid"
              borderRadius={4}
              borderColor="gray.500"
              paddingX={4}
              paddingY={2}
              whiteSpace="nowrap"
              overflowX="scroll"
              maxW={500}
              onDoubleClick={copyLink}
              _hover={{
                cursor: 'pointer'
              }}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
              display="flex"
              value={props.copyLink}
            />
            <Tooltip label="Copy link to clipboard">
              <IconButton
                aria-label="Copy link to clipboard"
                variant="outline"
                icon={<FontAwesomeIcon icon={faCopy} />}
                onClick={copyLink}
              />
            </Tooltip>
          </HStack>
        </>
      )}
      <Text>
        <span>For more details </span>
        <Link
          href="#"
          color="lime.200"
          textUnderlineOffset={2}
          textDecoration="underline"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            closeModal();
            window.HubSpotConversations.widget.open();
          }}
        >
          contact us.
        </Link>
      </Text>
    </VStack>
  );
};
