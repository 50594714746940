import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn
} from '@chakra-ui/react';

import {faCoins} from '@fortawesome/pro-thin-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface BuyCreditsModalProps {
  disclosure: UseDisclosureReturn;
}

const BuyCreditsModal: React.FC<BuyCreditsModalProps> = ({disclosure}) => {
  return (
    <Modal isCentered isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        paddingTop={20}
        paddingX="2rem"
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <ModalCloseButton />
        <ModalHeader paddingY={0}>
          <FontAwesomeIcon icon={faCoins} size="2x" />
        </ModalHeader>
        <ModalBody alignItems="center" paddingX={0} paddingTop={0} justifyContent="center">
          <Text fontSize="2.25rem" fontWeight={600} lineHeight={1} marginY={3} textAlign="center">
            No Credits? No problem
          </Text>
          <Text lineHeight={1.75} textAlign="center">
            You don&apos;t have any credits. Reach out to our team to get enrolled today.
          </Text>
        </ModalBody>
        <ModalFooter paddingBottom={12}>
          <Button
            variant="outline"
            onClick={() => {
              window.HubSpotConversations.widget.open();
              disclosure.onClose();
            }}
          >
            Contact us
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BuyCreditsModal;
