import React, {
  ChangeEventHandler,
  FC,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Button,
  ButtonProps,
  Center,
  CircularProgress,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import {useDisclosure} from '@chakra-ui/hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort} from '@fortawesome/pro-solid-svg-icons/faSort';
import {faArrowLeft, faSortDown, faSortUp} from '@fortawesome/pro-solid-svg-icons';
import {
  CohortTypeEnum,
  CourseTypeEnum,
  Scalars,
  useEnrollTeamMemberMutation,
  useGetSubordinateEnrollmentsLazyQuery
} from 'generated/graphql';
import _ from 'lodash';
import {LIMITED_SEATS_THRESHOLD} from 'utils/constants';
import TeamMemberAvatar from 'components/TeamMemberAvatar';
import {useAnalytics} from 'context/AnalyticsWebContext';
import {faCoins} from '@fortawesome/pro-thin-svg-icons';
import {useSession} from 'context/SessionContext';
import {useEnroll} from 'utils/enrollments/hooks/useEnroll';

interface Props {
  cohortId: Scalars['uuid'];
  isDisabled?: boolean;
  onEnrollmentChange?: (action: 'enroll' | 'unenroll', userIds: string[]) => void;
  onClose?: () => void;
  selectedMembers?: Scalars['uuid'][];
  size?: ButtonProps['size'];
  closeOnEnroll?: boolean;
}

const UnenrollButton: React.FC<
  PropsWithChildren & {
    cohortId: string;
    courseType?: CourseTypeEnum;
    onUnenroll: Props['onEnrollmentChange'];
    userId: string;
    userName: string;
  }
> = (props) => {
  const {unenroll, unenrolling} = useEnroll({
    id: props.cohortId,
    courseType: props.courseType,
    userId: props.userId,
    userName: props.userName,
    onEnrollmentChange: props.onUnenroll
  });

  return (
    <Button
      variant="link"
      color="berry"
      size="sm"
      _hover={{
        textDecoration: 'underline'
      }}
      textDecoration="none"
      textTransform="capitalize"
      isLoading={unenrolling}
      onClick={() => unenroll()}
    >
      {props.children}
    </Button>
  );
};

const TeamEnrollModal: FC<Props> = ({
  cohortId,
  isDisabled = false,
  onEnrollmentChange,
  onClose,
  selectedMembers: _selectedMembers = [],
  size,
  closeOnEnroll
}) => {
  const {data: session} = useSession();
  const {isOpen, onOpen: openModal, onClose: closeModal} = useDisclosure();
  const analyticsWebClient = useAnalytics();
  const showCreditsVisibilityPage = analyticsWebClient?.checkFeatureFlag(
    'show-credits-visibility-page'
  ) as boolean;
  const waitlistFeatureEnabled = analyticsWebClient?.checkFeatureFlag('enrollment-waitlist');

  const [mode, setMode] = useState<'review' | 'enroll'>('enroll');
  const [sortBy, setSortBy] = useState<
    'NAME_ASC' | 'NAME_DESC' | 'TEAM_ASC' | 'TEAM_DESC' | undefined
  >('NAME_ASC');
  const [selectedMembers, setSelectedMembers] = useState<string[]>(_selectedMembers);
  const [isSubordinateEnrollmentsLoading, setIsSubordinateEnrollmentsLoading] = useState(false);
  const [
    fetchSubordinateEnrollments,
    {data: subordinateEnrollmentsData, refetch: refetchEnrollments}
  ] = useGetSubordinateEnrollmentsLazyQuery({fetchPolicy: 'no-cache'});
  const [doEnrollLearner] = useEnrollTeamMemberMutation();
  // const [doUnenrollLearner] = useUnenrollTeamMemberMutation();

  const handleCloseModal = useCallback(() => {
    onClose?.();
    closeModal();
  }, [closeModal, onClose]);

  const handleOpenModal = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      openModal();
      setMode('enroll');
      setSortBy(undefined);
      setIsEnrolling(false);
      setEnrollProgress(0);
      setSelectedMembers(_selectedMembers);
      setIsSubordinateEnrollmentsLoading(true);
      fetchSubordinateEnrollments({
        variables: {
          cohortId
        }
      })
        .then(() => {
          setIsSubordinateEnrollmentsLoading(false);
        })
        .catch(console.error);
    },
    [openModal, _selectedMembers, fetchSubordinateEnrollments, cohortId]
  );

  const toggleMember = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const target = e.target;
    setSelectedMembers((prev) =>
      prev.includes(target.value) ? prev.filter((v) => v !== target.value) : [...prev, target.value]
    );
  }, []);

  const isLive = subordinateEnrollmentsData?.cohortsByPk?.type === CohortTypeEnum.LIVE;
  const totalSeats = subordinateEnrollmentsData?.cohortsByPk?.maxEnrollment ?? 0;
  const isUnlimitedSeats = totalSeats === 0;
  const waitlistedMemberIds = useMemo(
    () =>
      subordinateEnrollmentsData?.subordinates
        ?.filter((s) => s.waitlists?.includes(cohortId))
        .map<string>((s) => s.userId) ?? [],
    [subordinateEnrollmentsData?.subordinates, cohortId]
  );
  const enrolledMemberIds = useMemo(
    () =>
      subordinateEnrollmentsData?.subordinates
        ?.filter((s) => s.enrollments?.includes(cohortId))
        .map<string>((s) => s.userId) ?? [],
    [subordinateEnrollmentsData?.subordinates, cohortId]
  );

  const shouldShowCreditsIndicator = useMemo(() => {
    const creditsModel = session?.user.selectedOrg?.creditModel;
    const courseType = subordinateEnrollmentsData?.cohortsByPk?.course?.type;

    if (!showCreditsVisibilityPage) return false;
    if (creditsModel === 'legacy') return false;
    if (creditsModel === 'v1') return true;

    return courseType === CourseTypeEnum.LIGHTNING_SESSION;
  }, [
    showCreditsVisibilityPage,
    session?.user.selectedOrg?.creditModel,
    subordinateEnrollmentsData?.cohortsByPk?.course?.type
  ]);

  const totalSeatsLeft = Math.max(
    0,
    totalSeats - (subordinateEnrollmentsData?.cohortsByPk?.totalEnrollments ?? 0)
  );
  const totalMembers = subordinateEnrollmentsData?.subordinates?.length ?? 0;

  const enrollButtonLabel = useMemo(() => {
    if (
      waitlistFeatureEnabled &&
      subordinateEnrollmentsData?.cohortsByPk?.course?.type === CourseTypeEnum.LIGHTNING_SESSION
    ) {
      if (selectedMembers.length === 0) {
        return 'Enroll';
      }

      const waitlistedCount =
        selectedMembers.length > totalSeatsLeft ? selectedMembers.length - totalSeatsLeft : 0;
      const enrolledCount = selectedMembers.length - waitlistedCount;

      const labelParts: string[] = [];

      if (enrolledCount > 0) {
        labelParts.push(`Enroll ${enrolledCount} member${enrolledCount > 1 ? 's' : ''}`);
      }
      if (waitlistedCount > 0) {
        labelParts.push(`Wait-list ${waitlistedCount} member${waitlistedCount > 1 ? 's' : ''}`);
      }

      return labelParts.join(' & ');
    }

    if (selectedMembers.length > 0)
      return `Enroll ${selectedMembers.length} member${selectedMembers.length > 1 ? 's' : ''}`;
    else return 'Enroll';
  }, [
    subordinateEnrollmentsData?.cohortsByPk?.course?.type,
    selectedMembers,
    totalSeatsLeft,
    waitlistFeatureEnabled
  ]);

  const sortedSubordinates = useMemo(() => {
    const a = _(subordinateEnrollmentsData?.subordinates ?? []);
    let subs = subordinateEnrollmentsData?.subordinates ?? [];
    if (sortBy === 'NAME_ASC') subs = a.orderBy(['firstName', 'lastName'], ['asc', 'asc']).value();
    if (sortBy === 'NAME_DESC')
      subs = a.orderBy(['firstName', 'lastName'], ['desc', 'desc']).value();
    if (sortBy === 'TEAM_ASC') subs = a.orderBy(['teamNames'], ['asc']).value();
    if (sortBy === 'TEAM_DESC') subs = a.orderBy(['teamNames'], ['desc']).value();

    return subs.filter((s) =>
      mode === 'review'
        ? waitlistFeatureEnabled
          ? enrolledMemberIds.includes(s.userId) || waitlistedMemberIds.includes(s.userId)
          : enrolledMemberIds.includes(s.userId)
        : waitlistFeatureEnabled
        ? !enrolledMemberIds.includes(s.userId) && !waitlistedMemberIds.includes(s.userId)
        : !enrolledMemberIds.includes(s.userId)
    );
  }, [
    subordinateEnrollmentsData?.subordinates,
    sortBy,
    mode,
    enrolledMemberIds,
    waitlistedMemberIds,
    waitlistFeatureEnabled
  ]);

  const toggleAll = useCallback(() => {
    if (selectedMembers.length === sortedSubordinates.length) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(sortedSubordinates.map((s) => s.userId));
    }
  }, [sortedSubordinates, selectedMembers.length]);

  const nameSortIcon = !sortBy
    ? faSort
    : sortBy === 'NAME_ASC'
    ? faSortDown
    : sortBy === 'NAME_DESC'
    ? faSortUp
    : null;
  const teamSortIcon = !sortBy
    ? faSort
    : sortBy === 'TEAM_ASC'
    ? faSortDown
    : sortBy === 'TEAM_DESC'
    ? faSortUp
    : null;

  const toggleSortName = useCallback(() => {
    if (sortBy === 'NAME_ASC') setSortBy('NAME_DESC');
    else setSortBy('NAME_ASC');
  }, [sortBy]);

  const toggleSortTeam = useCallback(() => {
    if (sortBy === 'TEAM_ASC') setSortBy('TEAM_DESC');
    else setSortBy('TEAM_ASC');
  }, [sortBy]);

  const [isEnrolling, setIsEnrolling] = useState(false);
  const [enrollProgress, setEnrollProgress] = useState(0);

  // const [unenrollingUserId, setUnenrollingUserId] = useState<string | null>(null);
  const totalEnrolled = waitlistFeatureEnabled
    ? enrolledMemberIds.length + waitlistedMemberIds.length
    : enrolledMemberIds.length;

  const enroll = useCallback(async () => {
    if (mode === 'review') {
      if (isUnlimitedSeats || totalSeatsLeft > 0) {
        setMode('enroll');
      }
      return;
    }
    setIsEnrolling(true);
    let count = 0;
    let errorCount = 0;
    const enrolledUserIds: string[] = [];
    for (const userId of selectedMembers) {
      count++;
      setEnrollProgress(count);
      try {
        await doEnrollLearner({
          variables: {
            cohortId,
            userId,
            waitlistEnabled: waitlistFeatureEnabled as boolean
          }
        });
        enrolledUserIds.push(userId);
      } catch (e) {
        errorCount++;
        console.error(e);
      }
    }
    setMode('review');
    setIsEnrolling(false);
    setSelectedMembers([]);
    onEnrollmentChange?.('enroll', enrolledUserIds);
    if (closeOnEnroll) {
      handleCloseModal();
      return;
    }
    fetchSubordinateEnrollments({
      variables: {
        cohortId
      }
    }).catch(console.error);
    if (errorCount > 0) {
      alert(
        `${errorCount} team member${
          errorCount === 1 ? '' : 's'
        } could not be enrolled. Please check the enrollment list and try again or contact support.`
      );
    }
  }, [
    cohortId,
    doEnrollLearner,
    fetchSubordinateEnrollments,
    isUnlimitedSeats,
    mode,
    selectedMembers,
    totalSeatsLeft,
    waitlistFeatureEnabled,
    onEnrollmentChange,
    closeOnEnroll,
    handleCloseModal
  ]);

  const teamMembersEnrolled = useMemo(() => {
    return (
      <Text textColor="gray.300">
        {totalEnrolled} / {totalMembers} team member
        {totalMembers === 1 ? '' : 's'}
        {waitlistFeatureEnabled && waitlistedMemberIds.length > 0 ? (
          <>
            {' '}
            ({enrolledMemberIds.length} enrolled & {waitlistedMemberIds.length} waitlisted)
          </>
        ) : (
          <> enrolled</>
        )}
      </Text>
    );
  }, [totalEnrolled, totalMembers, waitlistFeatureEnabled, enrolledMemberIds, waitlistedMemberIds]);

  useEffect(() => {
    if (
      (!waitlistFeatureEnabled ||
        subordinateEnrollmentsData?.cohortsByPk?.course?.type === CourseTypeEnum.TRADITIONAL) &&
      selectedMembers.length > totalSeatsLeft &&
      !isUnlimitedSeats
    ) {
      setSelectedMembers(selectedMembers.slice(0, totalSeatsLeft));
    }
  }, [
    subordinateEnrollmentsData,
    selectedMembers,
    totalSeatsLeft,
    isUnlimitedSeats,
    waitlistFeatureEnabled
  ]);

  if (
    !waitlistFeatureEnabled &&
    !isUnlimitedSeats &&
    enrolledMemberIds.length === 0 &&
    totalSeatsLeft === 0
  ) {
    // do not show anything if there are no seats left and no one on team is enrolled
    return null;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size="4xl">
        <ModalOverlay />
        <ModalContent alignItems="flex-start" display="flex" p={4}>
          <ModalHeader>
            <Text size="md" mb={2}>
              {mode === 'review' ? 'Team members enrolled in' : 'Enroll your team in'}{' '}
              {subordinateEnrollmentsData?.cohortsByPk?.course?.name}
            </Text>
            {totalSeatsLeft <= LIMITED_SEATS_THRESHOLD &&
              totalSeatsLeft > 0 &&
              isLive &&
              !isUnlimitedSeats && (
                <Text color="lime.300" fontSize="md" fontWeight="400">
                  Only <strong>{totalSeatsLeft}</strong> seat{totalSeatsLeft === 1 ? '' : 's'} left
                  in this session
                </Text>
              )}
            {totalSeatsLeft === 0 && !isUnlimitedSeats && (
              <Text color="berry" fontSize="md" fontWeight="400">
                This session is full
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            {isSubordinateEnrollmentsLoading ? (
              <Center height="60vh">
                <CircularProgress isIndeterminate color="lime.300" trackColor="black" />
              </Center>
            ) : (
              <TableContainer maxHeight="60vh" height="60vh" overflowY="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {mode === 'enroll' && (
                        <Th width="1%">
                          <input
                            type="checkbox"
                            checked={selectedMembers.length === sortedSubordinates.length}
                            onChange={toggleAll}
                            style={{cursor: 'pointer'}}
                          />
                        </Th>
                      )}
                      <Th style={{cursor: 'pointer'}} onClick={toggleSortName}>
                        <HStack justifyContent="space-between">
                          <span>Member</span>
                          {nameSortIcon && <FontAwesomeIcon icon={nameSortIcon} size="lg" />}
                        </HStack>
                      </Th>
                      <Th style={{cursor: 'pointer'}} onClick={toggleSortTeam}>
                        <HStack justifyContent="space-between">
                          <span>Team</span>
                          {teamSortIcon && <FontAwesomeIcon icon={teamSortIcon} size="lg" />}
                        </HStack>
                      </Th>
                      <Th>&nbsp;</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sortedSubordinates.map((user) => (
                      <Tr key={user.userId}>
                        {mode === 'enroll' && (
                          <Td>
                            <input
                              type="checkbox"
                              value={user.userId}
                              onChange={toggleMember}
                              checked={selectedMembers.includes(user.userId)}
                              style={{cursor: 'pointer'}}
                            />
                          </Td>
                        )}
                        <Td>
                          <TeamMemberAvatar
                            email={user.email as string}
                            firstName={user.firstName as string}
                            lastName={user.lastName as string}
                          />
                        </Td>
                        <Td>{user.teamNames}</Td>
                        {mode === 'review' &&
                        (enrolledMemberIds.includes(user.userId) ||
                          (waitlistFeatureEnabled && waitlistedMemberIds.includes(user.userId))) ? (
                          <Td isNumeric>
                            <UnenrollButton
                              cohortId={cohortId}
                              courseType={subordinateEnrollmentsData?.cohortsByPk?.course?.type}
                              userId={user.userId}
                              userName={`${user.firstName} ${user.lastName}`}
                              onUnenroll={(action, userIds) => {
                                setIsSubordinateEnrollmentsLoading(true);
                                setTimeout(async () => {
                                  await refetchEnrollments();
                                  setIsSubordinateEnrollmentsLoading(false);
                                }, 1500);
                                onEnrollmentChange?.(action, userIds);
                              }}
                            >
                              {waitlistedMemberIds.includes(user.userId)
                                ? 'Remove from Waitlist'
                                : 'Unenroll'}
                            </UnenrollButton>
                          </Td>
                        ) : (
                          <Td isNumeric>&nbsp;</Td>
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <HStack justifyContent="space-between" mb={4} mt={6}>
              {selectedMembers.length > 0 &&
                totalEnrolled < totalMembers &&
                mode != 'review' &&
                ((waitlistFeatureEnabled &&
                  subordinateEnrollmentsData?.cohortsByPk?.course?.type ===
                    CourseTypeEnum.LIGHTNING_SESSION) ||
                  totalSeatsLeft > 0 ||
                  isUnlimitedSeats) && (
                  <Button
                    variant="ghost"
                    borderWidth={2}
                    borderColor="lime.300"
                    textTransform="capitalize"
                    onClick={enroll}
                    isLoading={isEnrolling}
                  >
                    {enrollButtonLabel}
                  </Button>
                )}
              {mode == 'review' && (
                <Button
                  variant="ghost"
                  borderWidth={2}
                  borderColor="lime.300"
                  textTransform="capitalize"
                  onClick={() => setMode('enroll')}
                  leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                >
                  Back
                </Button>
              )}
              <HStack spacing={4}>
                {shouldShowCreditsIndicator &&
                  !isEnrolling &&
                  selectedMembers.length > 0 &&
                  mode == 'enroll' && (
                    <HStack>
                      <FontAwesomeIcon icon={faCoins} />
                      <Text size="sm" lineHeight={1}>
                        {`${selectedMembers.length} credit${selectedMembers.length > 1 ? 's' : ''}`}
                      </Text>
                    </HStack>
                  )}
                {!isEnrolling &&
                mode == 'enroll' &&
                (enrolledMemberIds.length > 0 || waitlistedMemberIds.length > 0) ? (
                  <Link onClick={() => setMode('review')} textDecoration="underline">
                    {teamMembersEnrolled}
                  </Link>
                ) : (
                  !isEnrolling && teamMembersEnrolled
                )}
              </HStack>
              {isEnrolling && (
                <Progress
                  width={500}
                  colorScheme="lime"
                  value={Math.round((enrollProgress / selectedMembers.length) * 100)}
                />
              )}
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        isDisabled={isDisabled}
        variant="outline"
        textTransform="capitalize"
        onClick={handleOpenModal}
        size={size}
      >
        Enroll Team
      </Button>
    </>
  );
};

export default TeamEnrollModal;
