import {useRouter} from 'next/router';
import {useEffect} from 'react';
import createLogger from 'utils/logger';

const logger = createLogger('useAutoEnrollCohort');

const ENROLL_COHORT_ID = 'enroll_cohort_id';

export function useAutoEnrollCohort(
  cohortId: string,
  enroll?: (cohortId?: string) => Promise<void>,
  active?: boolean
): void {
  const router = useRouter();
  useEffect(() => {
    if (!active) return;
    const enroll_cohort_id = router.query[ENROLL_COHORT_ID];
    if (enroll_cohort_id === cohortId) {
      // strip query param and replace router history so reloads don't re-process enrollment
      enroll?.(cohortId)
        ?.catch((error: Error) => logger.error(`problem auto-enrolling post-auth`, error))
        .finally(() => {
          const query = {...router.query};
          delete query[ENROLL_COHORT_ID];
          router.replace({pathname: router.pathname, query}, undefined, {shallow: true});
        });
    }
  }, [cohortId, enroll, router, active]);
}

export function useAutoEnrollRedirectUri(cohortId: string | number): string {
  const baseUrl = location.href.split('?')[0]; // remove any existing query args
  return `${baseUrl}?${ENROLL_COHORT_ID}=${cohortId}`;
}
