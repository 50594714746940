import moment from 'moment';
import createLogger from 'utils/logger';

export function isBeforeUnenrollmentThresholdTime(
  cohortStartTime?: string,
  beforeSessionHourThreshold?: number
) {
  const logger = createLogger('isBeforeUnenrollmentThresholdTime');

  if (!cohortStartTime) throw Error('Cohort start time is required to validate unenroll time');

  const now = moment.utc();
  const cohortStart = moment.utc(cohortStartTime);

  if (beforeSessionHourThreshold !== undefined) {
    logger.info('Checking cohort start time with threshold');
    const diff = cohortStart.diff(now, 'hours');
    logger.info(
      {cohortStartTime: cohortStart.toISOString(), now: now.toISOString(), diffInHours: diff},
      'checking diff and before session unenroll threshold time'
    );
    return diff > beforeSessionHourThreshold;
  }

  logger.info('Checking cohort start time WITHOUT threshold');
  return now.isBefore(cohortStart);
}

export function isUnenrollmentBeforeSessionStartTime(cohortStartTime?: string) {
  return isBeforeUnenrollmentThresholdTime(cohortStartTime);
}
