import {Box, Text} from '@chakra-ui/react';
import {ContactUsButton} from 'components/Buttons';
import {FC} from 'react';

const WantMore: FC<React.PropsWithChildren<unknown>> = () => (
  <Box p="4rem" textAlign="center">
    <Text as="h3" fontSize="2xl" mb="1rem">
      Want More?
    </Text>
    <Text size="lg" marginTop={0}>
      Your current plan restricts your ability to enroll in additional courses.
    </Text>
    <Text size="lg">
      To gain <Text as="b">unlimited access</Text> to the world&apos;s best{' '}
      <Text as="abbr" title="Go to market">
        GTM
      </Text>{' '}
      coaches and courses please contact our team.
    </Text>
    <ContactUsButton mt="2rem" />
  </Box>
);

export default WantMore;
