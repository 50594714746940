import {Text, TextProps} from '@chakra-ui/react';
import {FC} from 'react';

const TextMessage: FC<React.PropsWithChildren<TextProps>> = ({children, ...props}) => (
  <Text {...props} textAlign="center" fontSize="lg">
    {children}
  </Text>
);

export default TextMessage;
