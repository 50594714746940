import {Button, Link as CLink} from '@chakra-ui/react';
import Link from 'next/link';
import {catalogPath} from 'utils/routeFactory';
import SuccessfulWaitlisted from '../Messages/SuccessfulWaitlisted';
import {closeModal} from '../AppModal';

export const youreWaitlisted = (courseName: string, onClick = () => closeModal()) => ({
  title: "You've joined the waitlist",
  Body: <SuccessfulWaitlisted name={courseName} />,
  Footer: (
    <>
      <Link href={catalogPath().catalog()} passHref>
        <Button size="lg" variant="outline" as={CLink} layerStyle="button-link" onClick={onClick}>
          Discover More Courses
        </Button>
      </Link>
    </>
  )
});
