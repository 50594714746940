import {useAnalytics} from 'context/AnalyticsWebContext';
import {useEnrollmentContext} from 'context/EnrollmentContext';
import {useSession} from 'context/SessionContext';
import {
  CourseTypeEnum,
  Scalars,
  useCheckEnrollmentStatusForSubordinateLazyQuery,
  useGetLedgerTailQuery,
  useGetUserEnrollmentsLazyQuery
} from 'generated/graphql';
import {useEffect, useMemo} from 'react';
import {CreditModel, useCreditModel} from './useCreditModel';

type UseCanLearnerEnrollInCohortReturnType = {
  canEnroll: boolean;
  isEnrollable: boolean;
  isEnrolled: boolean;
  isWaitlisted: boolean;
};

export const useCanLearnerEnrollInCohort = (
  userId: Scalars['uuid'],
  id: Scalars['uuid'],
  openForEnrollment: boolean,
  courseType?: CourseTypeEnum,
  actAsManager?: boolean
): UseCanLearnerEnrollInCohortReturnType => {
  const analyticsWebClient = useAnalytics();
  const {enrollmentStatus} = useEnrollmentContext();
  const {data: session} = useSession();

  const showCreditsVisibilityPage = analyticsWebClient?.checkFeatureFlag(
    'show-credits-visibility-page'
  ) as boolean;

  const creditModel = useCreditModel();

  const {data: ledgerTail} = useGetLedgerTailQuery({
    variables: {organizationId: session?.userMeta.selectedOrgId}
  });

  const [fetchUserEnrollments, {data: userEnrollments, called: enrollmentsCalled}] =
    useGetUserEnrollmentsLazyQuery();

  const [
    fetchSubordinateEnrollmentStatus,
    {data: subordinateEnrollments, called: subordinateEnrollmentsCalled}
  ] = useCheckEnrollmentStatusForSubordinateLazyQuery();

  useEffect(() => {
    // Enrollment status is defined in context. Acting as controller, we don't need to check it.
    if (enrollmentStatus) return;
    if (userId && !enrollmentsCalled && !actAsManager)
      fetchUserEnrollments({variables: {user_id: userId}});
  }, [userId, enrollmentsCalled, fetchUserEnrollments, actAsManager, enrollmentStatus]);

  useEffect(() => {
    // Enrollment status is defined in context. Acting as controller, we don't need to check it.
    if (enrollmentStatus) return;
    if (userId && !subordinateEnrollmentsCalled && actAsManager)
      fetchSubordinateEnrollmentStatus({
        variables: {
          cohortIds: [id],
          userId: userId
        }
      });
  }, [
    userId,
    subordinateEnrollmentsCalled,
    fetchSubordinateEnrollmentStatus,
    id,
    actAsManager,
    enrollmentStatus
  ]);

  const isEnrolled = useMemo(() => {
    if (enrollmentStatus) return enrollmentStatus === 'ENROLLED';
    if (actAsManager) {
      return (subordinateEnrollments?.enrollmentCount?.aggregate?.count ?? 0) > 0;
    } else {
      return userEnrollments?.user?.enrollments.some(({cohortId}) => cohortId === id) ?? false;
    }
  }, [
    userEnrollments?.user,
    id,
    actAsManager,
    subordinateEnrollments?.enrollmentCount,
    enrollmentStatus
  ]);

  const isWaitlisted = useMemo(() => {
    if (enrollmentStatus) return enrollmentStatus === 'WAITLISTED';
    if (actAsManager) {
      return (subordinateEnrollments?.waitlistCount?.aggregate?.count ?? 0) > 0;
    } else {
      return userEnrollments?.user?.waitlists.some(({cohortId}) => cohortId === id) ?? false;
    }
  }, [
    userEnrollments?.user,
    id,
    actAsManager,
    subordinateEnrollments?.waitlistCount,
    enrollmentStatus
  ]);

  const hasEnoughCredits = useMemo(() => {
    // We are checking whether the org has enough credits left because we want to allow them to overspend so we can invoice them the difference

    // If the user has no credit model, we can't determine if they have enough credits
    if (!creditModel) return false;

    if (!showCreditsVisibilityPage) return true;
    if (creditModel === CreditModel.LEGACY) return true;

    const subscriptionCreditsBought = ledgerTail?.ledger[0]?.subscriptionCreditsBought;

    if (subscriptionCreditsBought === undefined) return false;

    if (creditModel === CreditModel.V2 && courseType === CourseTypeEnum.TRADITIONAL) return true;

    return subscriptionCreditsBought > 0;
  }, [ledgerTail, creditModel, courseType, showCreditsVisibilityPage]);

  const isEnrollable = useMemo(() => {
    if (!openForEnrollment) return false;
    return hasEnoughCredits && (!enrollmentsCalled || !subordinateEnrollmentsCalled);
  }, [openForEnrollment, enrollmentsCalled, subordinateEnrollmentsCalled, hasEnoughCredits]);

  const canEnroll = useMemo(() => isEnrollable && !isEnrolled, [isEnrolled, isEnrollable]);

  return {
    canEnroll,
    isEnrolled,
    isEnrollable,
    isWaitlisted
  };
};
