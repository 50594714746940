import {useToast} from '@chakra-ui/react';
import {closeModal, openModal} from 'components/Modals/AppModal';
import {wantMore, youreEnrolled, enrollmentError} from 'components/Modals/configs';
import {unenrollmentError, unerollmentReason} from 'components/Modals/configs/enrollmentError';
import {managerOnlyEnrollment} from 'components/Modals/configs/managerOnlyEnrollmentError';
import {youreWaitlisted} from 'components/Modals/configs/youreWaitlisted';
import {useAnalytics} from 'context/AnalyticsWebContext';
import {useEnrollmentContext} from 'context/EnrollmentContext';
import {useSession} from 'context/SessionContext';
import {useSignInModal} from 'context/SignInModalContext';
import {
  Scalars,
  useEnrollLearnerMutation,
  useUnenrollLearnerMutation,
  useEnrollTeamMemberMutation,
  useUnenrollTeamMemberMutation,
  CourseTypeEnum
} from 'generated/graphql';
import {useCallback, useMemo} from 'react';
import {coursePath} from 'utils/routeFactory';
import {useAutoEnrollCohort, useAutoEnrollRedirectUri} from './auto-enroll-hook';
import {useCanLearnerEnrollInCohort} from './useCanLearnerEnrollInCohort';
import {CreditModel, useCreditModel} from './useCreditModel';
import {isBeforeUnenrollmentThresholdTime} from '../unenrollmentTimeUtils';

export type UseEnrollReturnType = {
  canEnroll: boolean;
  isEnrollable: boolean;
  isEnrolled: boolean;
  // isFull: boolean;
  isWaitlisted: boolean;
  // statusLoading: boolean;
  enrolling: boolean;
  unenrolling: boolean;
  enroll?: ReturnType<typeof useCallback>;
  unenroll: ReturnType<typeof useCallback>;
};

export const useEnroll = ({
  id,
  courseType,
  userId,
  userName,
  onEnrollmentChange
}: {
  id: Scalars['uuid'];
  userId?: Scalars['uuid'];
  userName?: string;
  courseType?: CourseTypeEnum;
  onEnrollmentChange?: (action: 'enroll' | 'unenroll', userIds: string[]) => void;
}): UseEnrollReturnType => {
  const {data: session} = useSession();
  const analyticsWebClient = useAnalytics();
  const waitlistFeatureEnabled = analyticsWebClient?.checkFeatureFlag('enrollment-waitlist');
  const {
    openForEnrollment,
    courseName,
    cohortId = id,
    cohortSlug,
    cohortStartTime,
    learnerName,
    learnerId,
    actAsSingleEnrollmentByManager
  } = useEnrollmentContext();

  const userAbilities = session?.user.abilities;

  /**
   * This hook can be used to enroll/unenroll users on someone's behalf.
   * The main way to do that is by setting `learnerId` on the EnrollmentContext.
   * It also accepts `userId` as a way to override the `learnerId` provided in the EnrollmentContext.
   */
  const selectedUserId = useMemo(
    () => userId ?? learnerId ?? session?.user?.id,
    [userId, learnerId, session?.user?.id]
  );
  const selectedUserName = useMemo(() => userName ?? learnerName, [userName, learnerName]);

  const actAsManager = useMemo(() => {
    if (!userAbilities?.editTeamEnrollments) return false;

    // If overriding learner user ID is provided, then assume the user is acting as manager
    if (userId && userName) return true;

    // If learner user ID is provided in the context, then assume the user is acting as manager
    return learnerId && actAsSingleEnrollmentByManager;
  }, [
    userAbilities?.editTeamEnrollments,
    learnerId,
    actAsSingleEnrollmentByManager,
    userId,
    userName
  ]);

  const toast = useToast();

  const creditModel = useCreditModel();

  const {canEnroll, isEnrollable, isEnrolled, isWaitlisted} = useCanLearnerEnrollInCohort(
    actAsManager ? learnerId : selectedUserId,
    id,
    openForEnrollment,
    courseType,
    actAsManager
  );

  const redirectUri = useAutoEnrollRedirectUri(id);
  const {openSignInModal} = useSignInModal();
  const [enrollLearner, {loading: enrolling}] = useEnrollLearnerMutation();
  const [unenrollLearner, {loading: unenrolling}] = useUnenrollLearnerMutation();
  const [enrollTeamMember, {loading: enrollingTeamMember}] = useEnrollTeamMemberMutation();
  const [unenrollTeamMember, {loading: unenrollingTeamMember}] = useUnenrollTeamMemberMutation();

  const unenrollmentWarningModalEnabled = analyticsWebClient?.checkFeatureFlag(
    '24hr_unenrollment_policy'
  );

  const enroll = useCallback(() => {
    if (actAsManager) {
      return enrollTeamMember({
        variables: {
          userId: selectedUserId,
          cohortId,
          waitlistEnabled: waitlistFeatureEnabled as boolean
        },
        refetchQueries: ['CheckEnrollmentStatusForSubordinate']
      })
        .then(({data, errors}) => {
          if (errors?.length) {
            toast({
              title: 'Something went wrong',
              description: 'Please try again.',
              status: 'error'
            });
          }

          const enrollmentId = data?.EnrollLearner?.enrollmentId;
          const waitlistId = data?.EnrollLearner?.waitlistId;

          if (enrollmentId) {
            toast({
              title: `${selectedUserName ?? 'Learner'} successfully enrolled ${courseName}.`,
              status: 'success'
            });
          } else if (waitlistId) {
            toast({
              title: `${selectedUserName ?? 'Learner'} successfully waitlisted ${courseName}.`,
              status: 'success'
            });
          }
          onEnrollmentChange?.('enroll', [selectedUserId]);
        })
        .catch((e: Error) => {
          if (e.message.includes('plan')) {
            return openModal(wantMore());
          }
          openModal(
            enrollmentError(e.message ?? 'There was an error enrolling learner in the course.')
          );
        });
    } else {
      return enrollLearner({
        variables: {
          cohortId,
          waitlistEnabled: waitlistFeatureEnabled as boolean
        },
        refetchQueries: ['GetUserEnrollments']
      })
        .then(({data, errors}) => {
          if (errors?.length) {
            toast({
              title: 'Something went wrong',
              description: 'Please try again.',
              status: 'error'
            });
          }

          const enrollmentId = data?.EnrollLearner?.enrollmentId;
          const waitlistId = data?.EnrollLearner?.waitlistId;

          if (enrollmentId) openModal(youreEnrolled(courseName, cohortSlug));
          else if (waitlistId) openModal(youreWaitlisted(courseName));
          onEnrollmentChange?.('enroll', selectedUserId ? [selectedUserId] : []);
        })
        .catch((e: Error) => {
          if (e.message.includes('manager')) {
            return openModal(
              managerOnlyEnrollment(
                'Your manager needs to enroll you in this course.',
                coursePath({absolute: true}).sessionDetail(cohortSlug)
              )
            );
          }
          if (e.message.includes('plan')) {
            return openModal(wantMore());
          }
          openModal(enrollmentError(e.message ?? 'There was an error enrolling in the course.'));
        });
    }
  }, [
    selectedUserId,
    selectedUserName,
    cohortId,
    cohortSlug,
    courseName,
    enrollLearner,
    toast,
    waitlistFeatureEnabled,
    actAsManager,
    onEnrollmentChange,
    enrollTeamMember
  ]);

  const unenroll = useCallback(
    (reason?: string) => {
      if (actAsManager) {
        return unenrollTeamMember({
          variables: {
            userId: selectedUserId,
            cohortId,
            waitlistEnabled: waitlistFeatureEnabled as boolean,
            reason
          },
          refetchQueries: ['CheckEnrollmentStatusForSubordinate']
        })
          .then(({data, errors}) => {
            if (errors?.length || !data?.UnenrollLearner?.unenrollmentStatus) {
              toast({
                title: 'Something went wrong',
                description: 'Please try again.',
                status: 'error'
              });
            }

            const waitlistId = data?.UnenrollLearner?.waitlistId;

            const title = waitlistId
              ? `You have successfully removed ${selectedUserName ?? 'Learner'} from the waitlist.`
              : `You’ve successfully unenrolled ${
                  selectedUserName ?? 'Learner'
                } from ${courseName}.`;

            toast({title, status: 'success'});
            onEnrollmentChange?.('unenroll', [selectedUserId]);
          })
          .catch((e: Error) => {
            openModal(
              unenrollmentError(
                e.message ?? 'There was an error unenrolling learner from the course.'
              )
            );
          });
      } else {
        return unenrollLearner({
          variables: {
            cohortId,
            waitlistEnabled: waitlistFeatureEnabled as boolean,
            reason
          },
          refetchQueries: ['GetUserEnrollments']
        })
          .then(({data, errors}) => {
            if (errors?.length) {
              toast({
                title: 'Something went wrong',
                description: 'Please try again.',
                status: 'error'
              });
            }

            const waitlistId = data?.UnenrollLearner?.waitlistId;

            const title = waitlistId
              ? `You have successfully been removed from the waitlist.`
              : `You’ve successfully unenrolled from ${courseName}.`;

            toast({title, status: 'success'});
            onEnrollmentChange?.('unenroll', selectedUserId ? [selectedUserId] : []);
          })
          .catch((e: Error) => {
            openModal(
              unenrollmentError(e.message ?? 'There was an error unenrolling from the course.')
            );
          });
      }
    },
    [
      selectedUserId,
      selectedUserName,
      unenrollLearner,
      toast,
      cohortId,
      courseName,
      waitlistFeatureEnabled,
      actAsManager,
      onEnrollmentChange,
      unenrollTeamMember
    ]
  );

  const unenrollWithWarning = useCallback(async () => {
    return new Promise<void>((resolve) => {
      if (!unenrollmentWarningModalEnabled) return unenroll('UNKNOWN');

      // check class event start time and show correct modal message
      const beforeSessionHourThreshold = 24;
      const isWithin24HoursPriorToSession = !isBeforeUnenrollmentThresholdTime(
        cohortStartTime,
        beforeSessionHourThreshold
      );
      const isInCreditsBasedModel =
        creditModel && [CreditModel.V1, CreditModel.V2].includes(creditModel);

      const message =
        courseType === CourseTypeEnum.LIGHTNING_SESSION &&
        isWithin24HoursPriorToSession &&
        isInCreditsBasedModel
          ? `Canceling within <b>${beforeSessionHourThreshold} hour${
              beforeSessionHourThreshold > 1 ? 's' : ''
            }</b> of the session will incur a <b>1 credit fee</b>. Are you sure you’d like to unenroll?`
          : // undefined will make the modal show the default message
            undefined;

      openModal(
        unerollmentReason({
          callback: (reason) => {
            unenroll(reason);
            closeModal();
            resolve();
          },
          message
        })
      );
    });
  }, [courseType, creditModel, cohortStartTime, unenroll, unenrollmentWarningModalEnabled]);

  const signIn = useCallback(async () => {
    if (!selectedUserId) return openSignInModal(redirectUri);
  }, [selectedUserId, openSignInModal, redirectUri]);

  const doEnroll = useMemo(() => {
    if (!selectedUserId) return signIn;
    if (!canEnroll && !isEnrollable) return;
    return enroll;
  }, [enroll, signIn, selectedUserId, canEnroll, isEnrollable]);

  const doUnenroll = useMemo(() => {
    if (!selectedUserId) return signIn;
    return unenrollWithWarning;
  }, [signIn, selectedUserId, unenrollWithWarning]);

  useAutoEnrollCohort(cohortId, doEnroll, !actAsManager);

  return {
    canEnroll,
    enrolling: enrolling || enrollingTeamMember,
    isEnrollable,
    isEnrolled,
    isWaitlisted,
    enroll: doEnroll,
    unenrolling: unenrolling || unenrollingTeamMember,
    unenroll: doUnenroll
  };
};
