import {useCallback, useState} from 'react';
import {ModalsBehaviorType, closeModal} from '../AppModal';
import TextMessage from '../Messages/Text';
import {
  VStack,
  Text,
  Link,
  Box,
  Select,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  Button
} from '@chakra-ui/react';

export const enrollmentError = (message?: string): ModalsBehaviorType => ({
  variant: 'error',
  title: 'Unable to enroll in course',
  Body: <TextMessage>{message}</TextMessage>,
  modalProps: {
    motionPreset: 'slideInBottom'
  }
});

export const unenrollmentError = (message?: string): ModalsBehaviorType => ({
  variant: 'error',
  title: 'Unable to unenroll from course',
  Body: unenrollmentBody(message),
  modalProps: {
    motionPreset: 'slideInBottom'
  }
});

const unenrollmentBody = (message?: string) => {
  return (
    <VStack>
      <TextMessage>{message}</TextMessage>
      <Text>
        <span>For more details </span>
        <Link
          href="#"
          color="lime.200"
          textUnderlineOffset={2}
          textDecoration="underline"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            closeModal();
            window.HubSpotConversations.widget.open();
          }}
        >
          contact us.
        </Link>
      </Text>
    </VStack>
  );
};

interface UnenrollmentReasonBodyProps {
  message?: string;
  callback?: (reason: string) => void;
}

export const UnenrollmentReasonBody = (props: UnenrollmentReasonBodyProps) => {
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');

  const handleUnenroll = useCallback(() => {
    const finalReason = reason === 'OTHER' ? otherReason : reason;
    props.callback?.(finalReason);
  }, [reason, otherReason, props]);

  return (
    <VStack maxW={420} margin="0 auto">
      <Box
        dangerouslySetInnerHTML={{
          __html:
            props.message ?? "Unenrolling now won't cost you anything. Would you like to unenroll?"
        }}
      />
      <VStack>
        <FormControl pt="28px">
          <FormLabel fontWeight="600" fontSize="16px">
            Reason for unenrollment{' '}
            <Text as="span" color="berry" fontWeight="600">
              *
            </Text>
          </FormLabel>
          <Select
            placeholder="Select Reason"
            size="lg"
            onChange={(event) => setReason(event.target.value)}
            borderRadius={0}
            borderColor="gray.30"
          >
            <option value="WRONG_COURSE_DATE_TIME">
              Enrolled in the wrong course, date, or time
            </option>
            <option value="SCHEDULING_CONFLICT">Unforeseen scheduling conflict</option>
            <option value="PERSONAL_EMERGENCY">Sudden personal or family emergency </option>
            <option value="OTHER">Other </option>
          </Select>
        </FormControl>
        {reason === 'OTHER' && (
          <Textarea
            onChange={(event) => setOtherReason(event.target.value)}
            placeholder="Tell us more"
            borderRadius={0}
            borderColor="gray.30"
          />
        )}
      </VStack>
      <HStack gap={2} pt={5}>
        <Button variant="outline" colorScheme="white" fontWeight="600">
          Go back
        </Button>
        <Button
          backgroundColor="berry"
          _hover={{backgroundColor: 'berry'}}
          fontWeight="600"
          isDisabled={!reason || (reason === 'OTHER' && !otherReason)}
          onClick={handleUnenroll}
        >
          Unenroll
        </Button>
      </HStack>
    </VStack>
  );
};

export const unerollmentReason = (props: UnenrollmentReasonBodyProps): ModalsBehaviorType => {
  return {
    title: 'Confirm Unenrollment',
    Body: <UnenrollmentReasonBody {...props} />,
    modalProps: {
      motionPreset: 'slideInBottom'
    }
  };
};
