import {Text} from '@chakra-ui/react';
import {FC} from 'react';

const SuccessfulWaitlisted: FC<React.PropsWithChildren<{name: string}>> = ({name}) => (
  <Text textAlign="center" fontSize="lg">
    When a seat becomes available, you&apos;ll be enrolled automatically in{' '}
    <Text as="b">{name}</Text> and notified via email.
  </Text>
);

export default SuccessfulWaitlisted;
